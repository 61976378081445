import TitleBoard from '../../../components/items/TitleBoard';
import HexagonCard from '../../../components/section4/HexagonCard';
import sticker1 from '../../../assets/sticker/1.webm';
import sticker2 from '../../../assets/sticker/2.webm';
import sticker3 from '../../../assets/sticker/3.webm';
import sticker4 from '../../../assets/sticker/4.webm';
import sticker5 from '../../../assets/sticker/5.webm';
import sticker6 from '../../../assets/sticker/6.webm';
import { fadeDown, fadeUp } from '../../../styles/animation/Animation';
const Section4 = () => {
  const CardTitle = [
    {
      title: 'Phase 1',
      isLeaf: true,
      CardValue: [
        'Game ideas and economic development',
        'Develop game UI and website',
        'Launch social accounts',
        'Check game and server security',
      ],
      variant: fadeDown,
      sticker: sticker1, // Add sticker here
    },
    {
      title: 'Phase 2',
      isLeaf: false,
      CardValue: [
        'Build the game environment',
        'In-game building',
        'Release internal beta test',
        'Initiate marketing plan',
        'Start presale',
      ],
      variant: fadeUp,
      sticker: sticker2, // Add sticker here
    },
    {
      title: 'Phase 3',
      isLeaf: true,
      CardValue: [
        'Pancakeswap',
        'Game Launch',
        'Airdrops',
        'Listing on CMC/CG',
      ],
      variant: fadeDown,
      sticker: sticker3, // Add sticker here
    },
    {
      title: 'Phase 4',
      isLeaf: false,
      CardValue: [
        'Partnerships',
        'Listing on CEX',
        'Adding other MEMES',
        'Freeing up more land',
      ],
      variant: fadeUp,
      sticker: sticker4, // Add sticker here
    },
    {
      title: 'Phase 5',
      isLeaf: true,
      CardValue: [
        'Increase Marketing',
        'Add more seeds',
        'Add more animals',
        "Visit your friend's farm",
      ],
      variant: fadeDown,
      sticker: sticker5, // Add sticker here
    },
    {
      title: 'Phase 6',
      isLeaf: false,
      CardValue: [
        'Own Blockchain',
        'Incorporate into our blockchain',
        'Marketing with major media outlets',
        'Update the roadmap',
      ],
      variant: fadeUp,
      sticker: sticker6, // Add sticker here
    },
  ];

  return (
    <section className="section4" id="Roadmap">
      <div className="wrapper container d-flex flex-column align-items-center justify-content-center gap-5">
        <div className=" col-8 col-md-5 col-xl-5">
          <TitleBoard content="Roadmap" />
        </div>
        <div className="d-flex justify-content-center justify-content-xxl-between flex-wrap gap-3">
          {CardTitle.map((item) => {
            return (
              <div className="section4_card" key={item.title}>
                <HexagonCard
                  isLeaf={item.isLeaf}
                  title={item.title}
                  content={item.CardValue}
                  key={item.title}
                  variants={item.variant}
                  sticker={item.sticker}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default Section4;
