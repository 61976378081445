import HexagonBoard from '../../assets/section/section4/hexagon_board.png';
import TextTitle from '../items/TextTitle';
import Leaf from '../../assets/section/section4/leaf.png';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const HexagonCard = ({ isLeaf, title, content, variants, sticker }) => {
  const ref = useInView();

  return (
    <motion.div
      className=" position-relative d-flex justify-content-center"
      ref={ref}
      variants={variants}
    >
      {/* <img
        src={HexagonBoard}
        alt="hexaBoard"
        className=" img-fluid w-100 h-auto"
      /> */}
      <div className="text-center card_title position-absolute start-50 translate-middle text-uppercase">
        {title}{' '}
      </div>

      <div className="position-absolute card_content w-75 pt-6">
        <div className="card_text ">
          {content.map((item) => {
            return <li key={item}>{item}</li>;
          })}
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          top: '250px', // fixed distance from the top
          right: '10px', // spacing from the right edge
          width: '20vw', // width as a percentage of the viewport width
          height: '20vw', // height as a percentage of the viewport width
          maxWidth: '100px', // maximum width to avoid growing too large
          maxHeight: '100px', // maximum height
        }}
      >
        <video
          src={sticker}
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain', // ensures the video scales without distortion
          }}
        />
      </div>
    </motion.div>
  );
};
export default HexagonCard;
