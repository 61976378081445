import TitleBoard from '../../../components/items/TitleBoard';
import Section3Middle from '../../../components/section3/Section3Middle';
import FeaturePicture from '../../../components/section3/FeaturePicture';
import TheFarm from '../../../assets/section/section3/feature_pic1.png';
import StoreHouse from '../../../assets/section/section3/feature_pic2.png';
import ReferralSytem from '../../../assets/section/section3/feature_pic3.png';
import SeedShop from '../../../assets/section/section3/feature_pic4.png';
import MoneyTree from '../../../assets/section/section3/feature_pic5.png';
import AddFriend from '../../../assets/section/section3/feature_pic6.png';
import Mask from '../../../assets/section/section3/mask.png';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { fadeUp } from '../../../styles/animation/Animation';

const Section3 = () => {
  const ref = useInView();

  const LeftValue = [
    {
      src: TheFarm,
      text: 'The Farm',
    },
    {
      src: StoreHouse,
      text: 'Store House',
    },
    {
      src: ReferralSytem,
      text: 'Referral Sytem',
    },
  ];

  const RightValue = [
    {
      src: SeedShop,
      text: 'Seed Shop',
    },
    {
      src: MoneyTree,
      text: 'Money Tree',
      isCommingSoon: true,
    },
    {
      src: AddFriend,
      text: 'Add Friend',
      isCommingSoon: true,
    },
  ];

  return (
    <div className="section3_wrapper">
      <motion.section
        className=" section3"
        id="WhitePaper"
        ref={ref}
        variants={fadeUp}
        initial="hidden"
        whileInView="visible"
      >
        <div className="container">
          <div className="section-mask">
            <img src={Mask} alt="" />
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center gap-5 position-relative">
            <div className="col-7 col-md-5 col-xl-5 d-flex justify-content-center">
              <TitleBoard content="Feature" />
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="section3_content col-10 col-xl-4 justify-content-around d-block d-xl-none">
                <Section3Middle
                  contents={[
                    'Welcome to Pepe Lands',
                    'In the world of cryptocurrencies, a green frog named Pepe began as a humble memecoin but dreamed big. To offer his followers something extraordinary, Pepe created Pepe Lands—a virtual escape where players can become millionaire farmers. In this magical land, you can grow crops like tomatoes, roses, and corn, while raising cows that produce fresh milk and hens that lay golden eggs.',
                    'Embark on the adventure of Pepe Lands, an NFT game where every harvest and effort can become a source of great profits! BSC $PPLS',
                  ]}
                  className="order-1"
                />
              </div>
              <div className="col-10 col-xl-4 py-4 d-flex gap-4 flex-column section3_content_left align-items-end">
                {LeftValue.map((item) => {
                  return (
                    <FeaturePicture
                      key={item.src}
                      src={item.src}
                      text={item.text}
                      isCommingSoon={item.isCommingSoon}
                    />
                  );
                })}
              </div>

              <div className="section3_content col-10 col-xl-4 justify-content-around d-none d-xl-block">
                <Section3Middle
                  contents={[
                    'Welcome to Pepe Lands',
                    'In the world of cryptocurrencies, a green frog named Pepe began as a humble memecoin but dreamed big. To offer his followers something extraordinary, Pepe created Pepe Lands—a virtual escape where players can become millionaire farmers. In this magical land, you can grow crops like tomatoes, roses, and corn, while raising cows that produce fresh milk and hens that lay golden eggs.',
                    'Embark on the adventure of Pepe Lands, an NFT game where every harvest and effort can become a source of great profits! BSC $PPLS',
                  ]}
                  className="order-1"
                />
              </div>

              <div className="section3-content_right col-10 col-xl-4 py-4 d-flex gap-4 flex-column section3_content_left">
                {RightValue.map((item) => {
                  return (
                    <FeaturePicture
                      key={item.src}
                      src={item.src}
                      text={item.text}
                      isCommingSoon={item.isCommingSoon}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </motion.section>
    </div>
  );
};
export default Section3;
