import React, { useState } from "react";
import { motion } from "framer-motion";
import { Modal, Stack } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import CloseButton from "../../assets/components/Closebutton.png";
import ImageTelegram from "../../assets/icons/telegram.svg";
import ImageInstagram from "../../assets/icons/instagram.svg";
import ImageTwitter from "../../assets/icons/x.svg";
import Coinsult from "../../assets/icons/coinsult.png";
import Dexscreener from "../../assets/icons/dexscreener.png";
import Dextools from "../../assets/icons/dextool.png";
import Logo from "../../assets/footer/footer_logo.png";
import Partner1 from "../../assets/footer/partner1.png";
import Partner2 from "../../assets/footer/partner2.png";
import Partner3 from "../../assets/footer/partner3.png";
import FooterContent from "../../components/footer/FooterContent";
import TopBackground from "../../components/footer/TopBackground";
import TitleBoard from "../../components/items/TitleBoard";
import { fadeUp } from "../../styles/animation/Animation";
import CenterImage from "../../assets/footer/center.png";
import TomatoIcon from "../../assets/token/tomato.png";
import CornIcon from "../../assets/token/corn.png";
import RoseIcon from "../../assets/token/rose.png";
import CarrotIcon from "../../assets/token/carrot.png";
import AppleIcon from "../../assets/token/apple.png";
import WatermelonIcon from "../../assets/token/watermelon.png";
import StrawberryIcon from "../../assets/token/strawberry.png";
import RocketIcon from "../../assets/token/rocket.png";
import MangoIcon from "../../assets/token/mango.png";
import GrapesIcon from "../../assets/token/grapes.png";







const title = "Tokenomics";

const FooterValue = [
  {
    title: "",
    content: [
      {
        name: "Total Supply: ",
        value: "1,000,000,000 $PPLS",
        icon: TomatoIcon,
        isLine: true,
      },
      { name: "FarmFi: ", value: "45%", icon: CornIcon, isLine: true },
      { name: "Marketing: ", value: "9%", icon: RoseIcon, isLine: true },
      { name: "Airdrop: ", value: "2%", icon: CarrotIcon, isLine: true },
    ],
  },
  {
    title: "",
    content: [
      <div className="d-flex justify-content-center">
        <img
          src={CenterImage}
          alt="Tokenomics Overview"
          width="100%"
          height="100%"
        />
      </div>    
    ],
  },
  {
    title: "",
    content: [
      {
        name: 'Cex: ',
        value: '5%',
        icon: MangoIcon,
        isLine: true,
      },
      {
        name: 'Presale: ',
        value: '25%',
        icon: RocketIcon,
        isLine: true,
      },
      {
        name: 'Liquidity: ',
        value: '10%',
        icon: AppleIcon,
        isLine: true,
      },
      { name: "Slip buy: ", value: "0%", icon: StrawberryIcon, isLine: true },
      { name: "Slip sell: ", value: "0%", icon: GrapesIcon, isLine: true },
    ],
  },
];

const Footer = () => {
  const { ref } = useInView();
  const [show, setShow] = useState(false);

  return (
    <div className="footer_wrapper">
      <motion.footer
        className="footer position-relative"
        id="Tokenomics"
        ref={ref}
        variants={fadeUp}
        initial="hidden"
        whileInView="visible"
      >
        <TopBackground />
        <div className="container d-flex gap-5 flex-column align-items-center justify-content-between mx-auto">
          <div className="col-7 col-md-5 col-xl-5">
            <TitleBoard content={title} />
          </div>

          <div className="row w-100 justify-content-between">
            {FooterValue.map((item, index) => (
              <FooterContent
                title={item.title}
                content={item.content}
                footerkey={index}
                key={index}
              />
            ))}
          </div>
        </div>

        <div className="footer_bg"></div>
        <div className="footer_form_wrapper">
          <div className="footer_form_mask">
            <div className="container d-flex flex-column flex-sm-row justify-content-between gap-2 gap-xxl-5 w-100 p-xxl-5">
              <div className="footer_form d-flex flex-column gap-3">
                <div className="d-flex justify-content-center gap-4">
                  <img
                    src={Partner1}
                    alt="Partner 1"
                    className="partner-logo"
                  />
                  <img
                    src={Partner2}
                    alt="Partner 2"
                    className="partner-logo"
                  />
                  <img
                    src={Partner3}
                    alt="Partner 3"
                    className="partner-logo"
                  />
                </div>
              </div>
              <div className="footer_logo d-flex flex-column gap-3 align-items-center">
                <img src={Logo} alt="" className="logo img-fluid h-auto" />
                <div className="d-flex flex-column align-items-center">
                  <span className="text-uppercase text-center">
                    © 2024 By Pepe Lands
                  </span>
                  <div>Build on BSC Network</div>
                </div>
                <div className="d-flex gap-3">
                <a
                      href="https://t.me/PEPE_LANDS"
                      target="_blank"
                      rel="noreferrer"
                      className="footer-icon"
                    >
                      <img src={ImageTelegram} alt="" className="" />
                    </a>
                    <a
                      href="https://x.com/PEPE_LANDS"
                      target="_blank"
                      rel="noreferrer"
                      className="footer-icon"
                    >
                      <img src={ImageTwitter} alt="" className="" />
                    </a>
                    <a
                      href="https://www.instagram.com/pepe_lands_official"
                      target="_blank"
                      rel="noreferrer"
                      className="footer-icon"
                    >
                      <img src={ImageInstagram} alt="" className="" />
                    </a>
                    <a
                      href="https://coinsult.net/projects/pepelands"
                      target="_blank"
                      rel="noreferrer"
                      className="footer-icon"
                    >
                      <img src={Coinsult} alt="" className="" />
                    </a>
                    <a
                      href="/"
                      target="_blank"
                      rel="noreferrer"
                      className="footer-icon"
                    >
                      <img src={Dexscreener} alt="" className="" />
                    </a>
                    <a
                      href="/"
                      target="_blank"
                      rel="noreferrer"
                      className="footer-icon"
                    >
                      <img src={Dextools} alt="" className="" />
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.footer>
    </div>
  );
};

export default Footer;
