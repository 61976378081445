import React, { useState } from "react";
import Board from "../../../components/items/Board";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { fadeDown } from "../../../styles/animation/Animation";
import Youtube from "../../../assets/components/youtube.png";
import videoFile from "../../../assets/video.mp4"; // Path to your local video file
import ReactPlayer from "react-player";

const Section2 = () => {
  const { ref } = useInView();
  const [isOpen, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <section className="section2 wrapper position-relative d-flex justify-content-center align-items-center">
      <div
        className="container d-flex flex-column align-items-center text-center"
        id="Overview"
      >
        <motion.div
          className="section2_content"
          ref={ref}
          variants={fadeDown}
          initial="hidden"
          whileInView="visible"
        >
          <Board
            title="TRAILER"
            isLightBoard={true}
            content={
              <>
                <div className="flex-end ">
                  <button
                    className="image-button"
                    onClick={() => setOpen(!isOpen)}
                  >
                    <img src={Youtube} alt="Youtube" />
                  </button>
                </div>
              </>
            }
          />
        </motion.div>
      </div>

      {/* Bootstrap Modal for Video */}
      <div
        className={`modal fade ${isOpen ? "show" : ""}`}
        id="videoModal"
        tabIndex="-1"
        aria-labelledby="videoModalLabel"
        aria-hidden="true"
        style={{ display: isOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              ></button>
            </div>
            <div className="modal-body">
              {/* Card content inside the modal */}
              <div className="card">
                <div className="card-body">
                  {/* ReactPlayer for Video */}
                  <div className="video-container">
                    <ReactPlayer
                      url={videoFile}
                      width="100%" // Make the video responsive
                      height="auto"
                      controls
                      playing={isOpen}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section2;
