import React, { useEffect, useState, useCallback, useMemo } from "react";
import BigNumber from "bignumber.js";
import toast, { Toaster } from "react-hot-toast";
import ConnectWalletButton from "../ConnectWalletButton";
import Usdt from "../../assets/currency/usdt.svg";
import Coin from "../../assets/currency/coin.png";
import {
  useAccount,
  useContractWrite,
  useContractRead,
  usePrepareContractWrite,
  useWaitForTransaction,
  useBalance,
} from "wagmi";
import { parseUnits, parseEther, formatEther, formatUnits } from "viem";
import presaleAbi from "../../abi/presale.json";
import erc20Abi from "../../abi/erc20.json";
import {
  tokenAdd,
  tokenUsdtAdd,
  contractAddr,
  chainId,
  name,
  symbol,
  decimal,
} from "../../config";

export default function UsdtCurrency() {
  const [usdtAmount, setUsdtAmount] = useState("");
  const [usdtErrorMessage, setUsdtErrorMessage] = useState("");
  const [allowance, setAllowance] = useState(new BigNumber(0));
  const [referralCode, setReferralCode] = useState("");
  const [isValidAmount, setIsValidAmount] = useState(true);

  const { address } = useAccount();

  useEffect(() => {
    if (address) {
      const referralCode = localStorage.getItem("referral")
        ? localStorage.getItem("referral")
        : "0x0000000000000000000000000000000000000000";
      setReferralCode(referralCode);
    }
  }, [address]);

  const balanceUsdt = useContractRead({
    address: tokenUsdtAdd,
    abi: erc20Abi,
    functionName: "balanceOf",
    args: [address],
    enabled: !!address,
    watch: true,
    chainId: chainId,
  });

  const usdtBal = new BigNumber(balanceUsdt.data || 0)
    .dividedBy(new BigNumber(10).pow(18))
    .toFixed(3);

  const usdtBalanceBig = new BigNumber(usdtBal);
  const isValidUsdt = usdtBalanceBig.gte(usdtAmount);

  const { config: approveConfig } = usePrepareContractWrite({
    address: tokenUsdtAdd,
    abi: erc20Abi,
    functionName: "approve",
    args: [contractAddr, parseUnits(usdtAmount, 18)],
    enabled: !!address && !!usdtAmount && !!isValidUsdt,
    chainId: chainId,
  });

  const { data: approveData, write: approveWrite } =
    useContractWrite(approveConfig);

  const {
    isLoading: approveIsLoading,
    isSuccess: approveIsSuccess,
    isError: approveIsError,
  } = useWaitForTransaction({
    hash: approveData?.hash,
  });

  const allowanceUsdtGet = useContractRead({
    address: tokenUsdtAdd,
    abi: erc20Abi,
    functionName: "allowance",
    enabled: !!address,
    args: [address, contractAddr],
    watch: true,
    chainId: chainId,
  });

  useEffect(() => {
    if (allowanceUsdtGet.data !== undefined) {
      const allowanceValue = new BigNumber(allowanceUsdtGet.data);
      setAllowance(allowanceValue);
    }
  }, [address, allowanceUsdtGet.data]);

  const { config } = usePrepareContractWrite({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "buyTokensWithUSDT",
    args: [parseUnits(usdtAmount, 18), referralCode],
    enabled: !!address && !!usdtAmount && !!allowance.gt(0) && !!isValidUsdt,
    chainId: chainId,
  });

  const { data: purchaseData, write: purchaseWrite } = useContractWrite(config);

  const {
    isLoading: purchaseIsLoading,
    isSuccess: purchaseIsSuccess,
    isError: purchaseIsError,
  } = useWaitForTransaction({
    hash: purchaseData?.hash,
  });

  const getAmount = useContractRead({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "getTokenAmountUSDT",
    args: [parseUnits(usdtAmount, 18)],
    enabled: !!usdtAmount,
    watch: true,
    chainId: chainId,
  });

  const getMinUsdtAmount = useContractRead({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "minAmountUSDT",
    watch: true,
    chainId: chainId,
  });

  const minAmount = new BigNumber(getMinUsdtAmount.data)
    .dividedBy(new BigNumber(10).pow(18))
    .toNumber();
  console.log("Min usdt", minAmount);

  const getResult = new BigNumber(getAmount.data);
  const result = isNaN(getResult)
    ? 0
    : getResult.dividedBy(new BigNumber(10).pow(18)).toFixed(3);

  useEffect(() => {
    if (purchaseIsSuccess) {
      toast.success(
        <div className="text-center py-2">
          Success! {symbol} Purchase Complete
          <div>
            <a
              style={{ color: "#fff" }}
              href={`https://bscscan.com/tx/${purchaseData?.hash}`}
              target="_blank"
            >
              View On Bscscan
            </a>
          </div>
        </div>
      );
      const timeout = setTimeout(() => {
        toast.dismiss();
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [purchaseIsSuccess, purchaseData?.hash]);

  useEffect(() => {
    if (purchaseIsError) {
      toast.error(
        <div className="text-center py-2">Error! Something Went Wrong</div>
      );
      const timeout = setTimeout(() => {
        toast.dismiss();
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [purchaseIsError]);

  useEffect(() => {
    if (approveIsSuccess) {
      toast.success(
        <div className="text-center py-2">
          Success! Approval Complete
          <div>
            <a
              style={{ color: "#fff" }}
              href={`https://bscscan.com/tx/${approveData?.hash}`}
              target="_blank"
            >
              View On BscScan
            </a>
          </div>
        </div>
      );
      const timeout = setTimeout(() => {
        toast.dismiss();
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [approveIsSuccess]);

  useEffect(() => {
    if (approveIsError) {
      toast.error(
        <div className="text-center py-2">Error! Something Went Wrong</div>
      );
      const timeout = setTimeout(() => {
        toast.dismiss();
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [approveIsError]);

  useEffect(() => {
    if (purchaseData?.hash) {
      setAllowance(new BigNumber(0));
    }
  }, [purchaseData?.hash]);

  const handleUsdtAmountChange = useMemo(
    () => (event) => {
      const inputValue = event.target.value;
      const parsedAmount = Number(inputValue);

      if (isNaN(parsedAmount) || parsedAmount <= 0) {
        setUsdtErrorMessage("Amount must be greater than zero");
        setIsValidAmount(false);
      } else if (usdtBal.toString() < parsedAmount) {
        setUsdtErrorMessage("Insufficient balance.");
        setIsValidAmount(false);
      } else if (parsedAmount < minAmount) {
        setUsdtErrorMessage(`Minimum buy amount is ${minAmount} USDT`);
        setIsValidAmount(false);
      } else {
        setUsdtErrorMessage("");
        setIsValidAmount(true);
      }
      setUsdtAmount(inputValue);
    },
    []
  );

  return (
    <>
      <div className="inputContainer">
        <div>
          <label htmlFor="paymentInput" className="inputLabel">
            Pay with USDT
          </label>
          <div className="inputBox">
            <input
              type="number"
              placeholder="0"
              name="usdtAmount"
              value={usdtAmount}
              onChange={handleUsdtAmountChange}
              step="any"
            />
            <img
              className="inputIcon"
              src={Usdt}
              width={24}
              height={24}
              alt="usdt"
            />
          </div>
        </div>
        <div>
          <label htmlFor="tokenInput" className="inputLabel">
            Receive {symbol}
          </label>
          <div className="inputBox">
            <input type="number" placeholder={result} readOnly />
            <img
              className="inputIcon"
              src={Coin}
              width={24}
              height={24}
              alt="logo"
            />
          </div>
        </div>
      </div>
      {usdtErrorMessage && (
        <div className="errorMessage">
          <p style={{ color: "#ff0000" }}>{usdtErrorMessage}</p>
        </div>
      )}

      {address ? (
        <>
          <div className="text-center">
            {allowance.toNumber() < usdtAmount ? (
              <button
                className="bg-btn"
                style={{ width: "100%" }}
                disabled={!approveWrite || approveIsLoading || !isValidAmount}
                onClick={() => approveWrite()}
              >
                {approveIsLoading ? "Approving..." : "Approve"}
              </button>
            ) : (
              <>
                <button 
                  type="button"
                  className="bg-btn"
                  style={{ width: "100%" }}
                  disabled={
                    !purchaseWrite || purchaseIsLoading || !isValidAmount
                  }
                  onClick={() => purchaseWrite()}
                >
                  {purchaseIsLoading ? "Buying..." : "Buy Now"}
                 </button>
              </>
            )}
          </div>
          <br />
        </>
      ) : (
        <div className="text-center mb-2">
          <ConnectWalletButton />
        </div>
      )}
    </>
  );
}
