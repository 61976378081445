import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import woodBoard from "../../assets/components/wood_board.png";
import { fadeDown } from "../../styles/animation/Animation";
import TextTitle from "./TextTitle";

const TitleBoard = ({ content }) => {
  const ref = useInView();
  return (
    <motion.div
      className="w-100"
      ref={ref}
      variants={fadeDown}
      initial="hidden"
      whileInView="visible"
    >
      <div className="position-relative d-flex justify-content-center align-items-center title-board-wrapper">
        <img
          src={woodBoard}
          alt="woodBoard"
          className="position-relative img-fluid w-100 h-auto"
        />
        <div className="position-absolute w-100 text-center t-0">
          <TextTitle content={content} isTitle={true} />
        </div>
      </div>
    </motion.div>
  );
};
export default TitleBoard;
