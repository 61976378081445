import React, { useEffect } from 'react';
import useParsedQueryString from '../hooks/useParsedQueryString';

const ReferralComponent = () => {
  const query = useParsedQueryString();

  useEffect(() => {
    if (query.ref) {
      const ref = query.ref;
      localStorage.setItem('referral', ref);
    }
  }, [query]);

  return <></>;
};

export default ReferralComponent;
