import { useState, useEffect } from 'react';

const useBnbPrice = () => {
  const [price, setPrice] = useState("");

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd');
        const data = await response.json();
        setPrice(data.binancecoin.usd);
      } catch (err) {
        console.log('Failed to fetch price');
      }
    };
    fetchPrice();
  }, []);

  return { price };
};

export default useBnbPrice;
