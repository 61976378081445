import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BigNumber from "bignumber.js";
import { useContractRead, useAccount } from "wagmi";
import presaleAbi from "../abi/presale.json";
import Copy from "../assets/currency/copy.svg";
import { parseUnits, formatEther, formatUnits } from "viem";
import ConnectWalletButton from "../components/ConnectWalletButton";
import ClipboardJS from "clipboard";
import {
  tokenAdd,
  tokenEthAdd,
  tokenUsdtAdd,
  contractAddr,
  chainId,
  name,
  symbol,
  decimal,
} from "../config";

const ReferralCard = () => {
  const { address } = useAccount();

  useEffect(() => {
    const handleCopy = (trigger, result) => {
      const feedback = document.querySelector(".copy-feedback");
      if (result === "success") {
        feedback.textContent = "Copied to Clipboard";
      } else {
        feedback.textContent = "Failed to Copy";
      }
      feedback.style.display = "block";
      setTimeout(() => {
        feedback.style.display = "none";
      }, 1000);
    };

    const clipboard = new ClipboardJS(".copy-clipboard")
      .on("success", (event) => {
        handleCopy(event.trigger, "success");
        event.clearSelection();
      })
      .on("error", (event) => {
        handleCopy(event.trigger, "fail");
      });

    const modalClipboard = new ClipboardJS(".copy-clipboard-modal", {
      container: document.querySelector(".modal"),
    })
      .on("success", (event) => {
        handleCopy(event.trigger, "success");
        event.clearSelection();
      })
      .on("error", (event) => {
        handleCopy(event.trigger, "fail");
      });

    return () => {
      clipboard.destroy();
      modalClipboard.destroy();
    };
  }, []);

  const getRefCount = useContractRead({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "referralsCount",
    args: [address],
    watch: true,
    enabled: !!address,
    chainId: chainId,
  });

  const getRefAmount = useContractRead({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "refTotalAmounts",
    args: [address],
    watch: true,
    enabled: !!address,
    chainId: chainId,
  });

  const getReferRate = useContractRead({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "referRate",
    watch: true,
    chainId: chainId,
  });

  const getClaimTime = useContractRead({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "claimTime",
    watch: true,
    chainId: chainId,
  });

  const refAmount = new BigNumber(getRefAmount.data);
  const resultAmount = isNaN(refAmount)
    ? 0
    : new BigNumber(refAmount).dividedBy(new BigNumber(10).pow(18)).toFixed(3);
  const refCount = new BigNumber(getRefCount.data);
  const resultCount = isNaN(refCount) ? 0 : new BigNumber(refCount).toNumber();
  const referBonus = new BigNumber(getReferRate.data).toNumber();

  return (
    <div className="cardReferral">
      <div className="card-body">
        <h2 className="text-center mb-4">Referral Program</h2>
        <h4>Earn with Referral</h4>
        <p>Invite your friends and family and receive free {symbol}</p>
        <p>
          Each member have a unique referral link to share with friends and
          family and receive a bonus - {referBonus.toString()}% of the value of
          their contribution.
        </p>
        <div className="row mb-3">
          <div className="col-6">
            <div className="fw-bold">Your Referrals</div>
            <div>{resultCount}</div>
            <div className="fw-bold">Your Earnings</div>
            <div>
              {resultAmount} {symbol}
            </div>
          </div>
        </div>
        <h5>Your Unique Referral Link:</h5>
        <div className="copy-wrap mgb-0-5x">
          <span className="copy-feedback" style={{ display: "none" }}>
            Copied to Clipboard
          </span>
          {address ? (
            <>
              <input
                type="text"
                className="copy-address"
                value={`https://pepelands.com/?ref=${address}`}
                disabled
              />
              <button
                className="copy-trigger copy-clipboard"
                data-clipboard-text={`https://pepelands.com/?ref=${address}`}
              >
                <img src={Copy} alt="copy" width="24" height="24" />
              </button>
            </>
          ) : (
            <div className="text-center mb-2 mt-2">
              <ConnectWalletButton />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReferralCard;
