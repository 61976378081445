import React, { useEffect, useState, useCallback, useMemo } from "react";
import BigNumber from "bignumber.js";
import toast, { Toaster } from "react-hot-toast";
import ConnectWalletButton from "../ConnectWalletButton";
import Bnb from "../../assets/currency/bnb.svg";
import Coin from "../../assets/currency/coin.png";
import {
  useAccount,
  useContractWrite,
  useContractRead,
  usePrepareContractWrite,
  useWaitForTransaction,
  useBalance,
} from "wagmi";
import { parseUnits, parseEther, formatEther, formatUnits } from "viem";
import presaleAbi from "../../abi/presale.json";
import erc20Abi from "../../abi/erc20.json";
import {
  tokenAdd,
  tokenUsdtAdd,
  contractAddr,
  chainId,
  name,
  symbol,
  decimal,
} from "../../config";

export default function BnbCurrency() {
  const [bnbAmount, setBnbAmount] = useState("");
  const [bnbErrorMessage, setBnbErrorMessage] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [isValidAmount, setIsValidAmount] = useState(true);

  const { address } = useAccount();

  useEffect(() => {
    if (address) {
      const referralCode = localStorage.getItem("referral")
        ? localStorage.getItem("referral")
        : "0x0000000000000000000000000000000000000000";
      setReferralCode(referralCode);
    }
  }, [address]);

  const balanceBnb = useBalance({
    address: address,
    chainId: chainId,
    enabled: !!address,
    watch: true,
  });

  const { config } = usePrepareContractWrite({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "buyTokensWithBNB",
    args: [referralCode],
    value: [parseEther(bnbAmount)],
    enabled: !!address && !!bnbAmount,
    chainId: chainId,
  });

  const { data, write } = useContractWrite(config);

  const { isLoading, isSuccess, isError } = useWaitForTransaction({
    hash: data?.hash,
  });

  const getAmount = useContractRead({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "getTokenAmountBNB",
    args: [parseEther(bnbAmount)],
    enabled: !!bnbAmount,
    watch: true,
    chainId: chainId,
  });

  const getMinBnbAmount = useContractRead({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "minAmountBNB",
    watch: true,
    chainId: chainId,
  });

  const minAmount = new BigNumber(getMinBnbAmount.data)
    .dividedBy(new BigNumber(10).pow(18))
    .toNumber();
  console.log("Min bnb", minAmount);

  const getBnbResult = new BigNumber(getAmount.data);
  const result = isNaN(getBnbResult)
    ? 0
    : new BigNumber(getBnbResult)
        .dividedBy(new BigNumber(10).pow(18))
        .toFixed(3);

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        <div className="text-center py-2">
          Success! {symbol} Purchase Complete
          <div>
            <a
              style={{ color: "#fff" }}
              href={`https://bscscan.com/tx/${data?.hash}`}
              target="_blank"
            >
              View On Bscscan
            </a>
          </div>
        </div>
      );
      const timeout = setTimeout(() => {
        toast.dismiss();
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error(
        <div className="text-center py-2">Error! Something Went Wrong</div>
      );
      const timeout = setTimeout(() => {
        toast.dismiss();
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [isError]);

  const handlebnbAmountChange = useMemo(
    () => (event) => {
      const inputValue = event.target.value;
      const parsedAmount = Number(inputValue);

      if (isNaN(parsedAmount) || parsedAmount <= 0) {
        setBnbErrorMessage("Amount must be greater than zero");
        setIsValidAmount(false);
      } else if (balanceBnb.data?.formatted < parsedAmount) {
        setBnbErrorMessage("Insufficient balance.");
        setIsValidAmount(false);
      } else if (parsedAmount < minAmount) {
        setBnbErrorMessage(`Minimum buy amount is ${minAmount} BNB`);
        setIsValidAmount(false);
      } else {
        setBnbErrorMessage("");
        setIsValidAmount(true);
      }
      setBnbAmount(inputValue);
    },
    [balanceBnb.data?.formatted, minAmount]
  );

  return (
    <>
      <div className="inputContainer">
        <div>
          <label htmlFor="paymentInput" className="inputLabel">
            Pay with BNB
          </label>
          <div className="inputBox">
            <input
              type="number"
              placeholder="0"
              name="bnbAmount"
              value={bnbAmount}
              onChange={handlebnbAmountChange}
              step="any"
            />
            <img
              className="inputIcon"
              src={Bnb}
              width={24}
              height={24}
              alt="logo"
            />
          </div>
        </div>
        <div>
          <label htmlFor="tokenInput" className="inputLabel">
            Receive {symbol}
          </label>
          <div className="inputBox">
            <input type="number" placeholder={result} readOnly />
            <img
              className="inputIcon"
              src={Coin}
              width={24}
              height={24}
              alt="coin"
            />
          </div>
        </div>
      </div>

      {bnbErrorMessage && (
        <div className="errorMessage">
          <p style={{ color: "#ff0000" }}>{bnbErrorMessage}</p>
        </div>
      )}

      {address ? (
        <>
          <div className="text-center">
           <button
              type="button"
              className="bg-btn"
              style={{ width: "100%" }}
              disabled={!write || isLoading || !isValidAmount}
              onClick={() => write?.()}
            >
              {isLoading ? "Buying..." : "Buy Now"}
            </button>
          </div>
          <br />
        </>
      ) : (
        <div className="text-center mb-2">
          <ConnectWalletButton />
        </div>
      )}
    </>
  );
}
