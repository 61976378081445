import ContentImg from '../../assets/components/Content.png';

const ContentBoard = ({ content }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-start board_container"
      style={{
        backgroundImage: `url(${ContentImg})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {/* <img
        src={ContentImg}
        alt="ContentBoard"
        className="d-none d-lg-block img-fluid h-auto"
      /> */}
      {content && (
        <div>
          {content}
        </div>
      )}
    </div>
  );
};
export default ContentBoard;
