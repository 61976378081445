import Line from "../items/Line";
import React from "react";

const FooterContent = ({ title, content, footerkey }) => {
  return (
    <div className="footer_content d-flex flex-column col-md-3" key={footerkey}>
      <span className="text-uppercase pb-4 footer-title">{title}</span>
      {content.map((item, index) => {
        if (React.isValidElement(item)) {
          return (
            <div key={index} className="mb-4">
              {item}
            </div>
          );
        }

        const { isLine, name, value, icon } = item;

        return (
          <div key={index}>
            {isLine ? (
              <div className="mb-3">
                {icon && <img src={icon} alt={name} className="icon-img" />}
                {name}
                <span className="font-weight-normal">{value}</span>
                <Line />
              </div>
            ) : (
              <div className="mb-3">
                {icon && <img src={icon} alt={name} className="icon-img" />}
                {name}
                <span className="font-weight-normal">{value}</span>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default FooterContent;
