import React, { useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import Usdt from "../../../assets/currency/usdt.svg";
import Bnb from "../../../assets/currency/bnb.svg";
import "../../../styles/components/layout/section/presale.css";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { appear } from "../../../styles/animation/Animation";
import CountdownTimer from "../../../components/CountdownTimers";
import BnbCurrency from "../../../components/currency/BnbCurrency";
import UsdtCurrency from "../../../components/currency/UsdtCurrency";
import ConnectWalletButton from "../../../components/ConnectWalletButton";
import ReferralCard from "../../../components/ReferralCard";
import useBnbPrice from "../../../hooks/useBnbPrice";
import Gif from "../../../assets/buygif.gif";
import ImageTelegram from "../../../assets/icons/telegram.svg";
import ImageInstagram from "../../../assets/icons/instagram.svg";
import ImageTwitter from "../../../assets/icons/x.svg";
import Coinsult from "../../../assets/icons/coinsult.png";
import Dexscreener from "../../../assets/icons/dexscreener.png";
import Dextools from "../../../assets/icons/dextool.png";

import {
  useAccount,
  useContractWrite,
  useContractRead,
  usePrepareContractWrite,
  useWaitForTransaction,
  useBalance,
} from "wagmi";
import { parseUnits, parseEther, formatEther, formatUnits } from "viem";
import presaleAbi from "../../../abi/presale.json";
import erc20Abi from "../../../abi/erc20.json";
import {
  tokenAdd,
  tokenUsdtAdd,
  contractAddr,
  chainId,
  name,
  symbol,
  decimal,
} from "../../../config";
import { Link } from "react-router-dom";

const Presale = () => {
  const ref = useInView();
  const { address } = useAccount();

  const [selectedCurrency, setSelectedCurrency] = useState("bnb");

  const { price } = useBnbPrice();

  const getTokenBalance = useContractRead({
    address: tokenAdd,
    abi: erc20Abi,
    functionName: "balanceOf",
    args: [address],
    enabled: !!address,
    watch: true,
    chainId: chainId,
  });

  const getUserTotalTokens = useContractRead({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "userTotalTokens",
    enabled: !!address,
    args: [address],
    watch: true,
    chainId: chainId,
  });

  const getTotalSold = useContractRead({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "tokenSold",
    watch: true,
    chainId: chainId,
  });

  const getTokenPrice = useContractRead({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "usdRate",
    watch: true,
    chainId: chainId,
  });

  const getNextPrice = useContractRead({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "nextPrice",
    watch: true,
    chainId: chainId,
  });

  const getTotalBnb = useContractRead({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "totalBnbRaised",
    watch: true,
    chainId: chainId,
  });

  const getTotalUsdt = useContractRead({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "totalUsdtRaised",
    watch: true,
    chainId: chainId,
  });

  const getPriceTime = useContractRead({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "nextPriceTime",
    watch: true,
    chainId: chainId,
  });

  const priceTime = new BigNumber(getPriceTime.data);
  const currentPrice = new BigNumber(getTokenPrice.data)
    .dividedBy(new BigNumber(10).pow(18))
    .toFixed(5);
  const nextPrice = new BigNumber(getNextPrice.data)
    .dividedBy(new BigNumber(10).pow(18))
    .toFixed(5);
  const tokenBal = new BigNumber(getTokenBalance.data || 0)
    .dividedBy(new BigNumber(10).pow(18))
    .toFixed(3);
  const userPurchaseToken = new BigNumber(getUserTotalTokens.data || 0)
    .dividedBy(new BigNumber(10).pow(18))
    .toFixed(3);
  // const totalSold = new BigNumber(getTotalSold.data).dividedBy(new BigNumber(10).pow(18)).toFixed(3);
  const totalBnb = new BigNumber(getTotalBnb.data).dividedBy(
    new BigNumber(10).pow(18)
  );
  const totalUsdt = new BigNumber(getTotalUsdt.data).dividedBy(
    new BigNumber(10).pow(18)
  );
  const bnbUsdAmount = totalBnb.multipliedBy(price);
  const totalUsdValue = bnbUsdAmount.plus(totalUsdt);
  const totalUsdValueDisplay = totalUsdValue.toFixed(0);
  const targetUsd = new BigNumber(250000); // target usd amount
  const progressPercentage = new BigNumber(totalUsdValue)
    .dividedBy(targetUsd)
    .multipliedBy(100);

  return (
    <section className="presale d-flex align-items-center" id="topPage">
      <div className="container">
        <motion.div
          className="wrapper d-flex flex-column justify-content-center align-items-center row"
          ref={ref}
          variants={appear}
          initial="hidden"
          whileInView="visible"
        >
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="text-center mt-2 mb-2">PRICE INCREASE EVERY 24 HOURS</div>
              <div className="cardPresale">
                <div className="card-body">
                  <h4 className="text-center">Until next price increase</h4>
                  <div className="mb-2">
                    <CountdownTimer endTime={priceTime} />
                  </div>
                  <div className="progress mb-2">
                    <div
                      className="custom-progress progress-bar"
                      role="progressbar"
                      style={{
                        width: `${
                          isNaN(progressPercentage.toFixed(3))
                            ? 0
                            : progressPercentage.toFixed(3)
                        }%`,
                        backgroundColor: "#75c84e",
                      }}
                      aria-valuenow={progressPercentage}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                  <p align="center" style={{ fontSize: "14px" }}>
                    USD RAISED: ${totalUsdValueDisplay.toString()} / $
                    {targetUsd.toString()}
                  </p>
                  <div className="divider-with-text mb-3">
                    <span>
                      1 {symbol} = ${currentPrice.toString()}
                    </span>
                  </div>
                  <div className="divider-with-text mb-3">
                    <span>Next Price = ${nextPrice.toString()}</span>
                  </div>
                  {address && (
                    <>
                      <div
                        className="text-center mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        Your purchased {symbol}: {userPurchaseToken.toString()}
                      </div>
                      <div
                        className="text-center mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        Available Balance {symbol}: {tokenBal.toString()}
                      </div>
                    </>
                  )}
                  <div className="row justify-content-center mb-2">
                    <div className="col">
                      <button
                        type="button"
                        className={`custom-button ${
                          selectedCurrency === "bnb" ? "active" : ""
                        }`}
                        onClick={() => setSelectedCurrency("bnb")}
                      >
                        <img src={Bnb} alt="BNB" className="img-fluid icon" />
                        <span>BNB</span>
                      </button>
                    </div>
                    <div className="col">
                      <button
                        type="button"
                        className={`custom-button ${
                          selectedCurrency === "usdt" ? "active" : ""
                        }`}
                        onClick={() => setSelectedCurrency("usdt")}
                      >
                        <img src={Usdt} alt="USDT" className="img-fluid icon" />
                        <span>USDT</span>
                      </button>
                    </div>
                    <div className="mt-2 mb-2">
                      {selectedCurrency === "bnb" && <BnbCurrency />}
                      {selectedCurrency === "usdt" && <UsdtCurrency />}
                    </div>
                  </div>
                  {address && (
                    <div className="text-center mb-2 mt-2">
                      <ConnectWalletButton />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4 text-center">
              <div className="gig-card">
                <img
                  src={Gif}
                  alt="Gig"
                  className="img-fluid rounded mb-3"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
                <div className="text-center">
                  <h3 className="presale-title">PEPE LANDS</h3>
                  <h4 className="presale-subtitle">MILLIONAIRE</h4>
                  <p className="presale-description">
                    Embark on the adventure of Pepe Lands,🌾 an NFT game, where
                    every harvest and care can become a source of great profits!
                    BSC $PPLS
                  </p>
                  <div className="social-media-links">
                    <a
                      href="https://t.me/PEPE_LANDS"
                      target="_blank"
                      rel="noreferrer"
                      className="social-icon"
                    >
                      <img src={ImageTelegram} alt="" className="" />
                    </a>
                    <a
                      href="https://x.com/PEPE_LANDS"
                      target="_blank"
                      rel="noreferrer"
                      className="social-icon"
                    >
                      <img src={ImageTwitter} alt="" className="" />
                    </a>
                    <a
                      href="https://www.instagram.com/pepe_lands_official"
                      target="_blank"
                      rel="noreferrer"
                      className="social-icon"
                    >
                      <img src={ImageInstagram} alt="" className="" />
                    </a>
                    <a
                      href="https://coinsult.net/projects/pepelands"
                      target="_blank"
                      rel="noreferrer"
                      className="social-icon"
                    >
                      <img src={Coinsult} alt="" className="" />
                    </a>
                    <a
                      href="/"
                      target="_blank"
                      rel="noreferrer"
                      className="social-icon"
                    >
                      <img src={Dexscreener} alt="" className="" />
                    </a>
                    <a
                      href="/"
                      target="_blank"
                      rel="noreferrer"
                      className="social-icon"
                    >
                      <img src={Dextools} alt="" className="" />
                    </a>
                  </div>
                  <div className="addressText">
                    <button className="bg-btn-address">
                        <a 
                          href="https://bscscan.com/token/0xbeB908039F9D6f6E8E80D57a613596E3227F389F"
                          style={{color: "#fff", fontSize: "14px", textDecoration: "none"}}
                          target="_blank"
                        >
                        0xbeB908039F9D6f6E8E80D57a613596E3227F389F
                        </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <ReferralCard />
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Presale;
