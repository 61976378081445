import { useMemo } from 'react';
import { parse } from 'qs';

const useParsedQueryString = () => {
  const queryString = window.location.search;
  return useMemo(() => {
    if (!queryString) return {};
    return parse(queryString, { ignoreQueryPrefix: true, parseArrays: false });
  }, [queryString]);
};

export default useParsedQueryString;
